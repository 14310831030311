<script>
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import List from "./pages/List";
import { mapState } from "vuex";

export default {
  name: "VirtualAgentUsageForWorkflowIndex",
  mixins: [BaseIndex],
  components: {
    List
  },
  data() {
    return {
      selectedContent: null,
      scope: "sys",
      contentType: "workflow",
      editable: false
    };
  },
  computed: {
    ...mapState("usages", {
      workflowUsages: state => state.workflowUsages
    })
  }
};
</script>

<style scoped></style>
