<template>
  <div style="padding: 0 36px; width: 100%">
    <div style="display: flex; color: #696969; padding-left: 10px">
      <div v-if="this.selectedAccountId !== 'all'">
        <div style="display: flex" v-loading="isLoading">
          <div>{{ __("Number of Runs Left") }}:</div>

          <el-tooltip
            :disabled="!checkFeatureWarningLimitReached"
            :content="checkFeatureWarningLimitTooltip"
            placement="right"
          >
            <code
              :style="checkFeatureWarningLimitReached && { color: 'red' }"
              >{{ featureUsageAndLimit.featureUsagesLeft }}</code
            >
          </el-tooltip>
        </div>
      </div>
    </div>
    <el-form class="form-container">
      <advanced-toolbar
        @dateRangeChanged="handleDateRangeChanged"
        :content-api="contentAPI"
      />
    </el-form>
    <el-scrollbar :native="true" style="margin-top: 20px;">
      <el-table
        :default-sort="{ prop: 'total_number_of_runs', order: 'descending' }"
        :data="usagesList"
        style="width: 100%"
        row-key="ac_id"
        v-loading="isLoading"
        class="list-table"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
              :default-sort="{
                prop: 'total_number_of_runs',
                order: 'descending'
              }"
              row-key="task_id"
              :data="props.row.tasks"
            >
              <el-table-column
                sortable
                :label="__('Task')"
                prop="task.task_name"
              >
                <template slot-scope="{ row }">
                  {{ (row.task && row.task.task_name) || __("Deleted task") }}
                </template>
              </el-table-column>
              <el-table-column
                sortable
                :label="__('Total Executions')"
                prop="total_number_of_runs"
              />
              <el-table-column
                sortable
                :label="__('Total Duration')"
                prop="total_run_time"
              >
                <template slot-scope="scope">
                  {{
                    convertMicroSecondsDurationToHumanReadableFormat(
                      scope.row.total_run_time
                    )
                  }}
                </template>
              </el-table-column>

              <el-table-column :label="__('Detail')">
                <template slot-scope="scope">
                  <el-button @click="handleDetailedLogClick(scope.row)">{{
                    __("More")
                  }}</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          :label="__('Account')"
          prop="account.ac_name"
        />
        <el-table-column
          sortable
          :label="__('Total Executions')"
          prop="total_number_of_runs"
        />
        <el-table-column
          sortable
          :label="__('Total Duration')"
          prop="total_run_time"
        >
          <template slot-scope="scope">
            {{
              convertMicroSecondsDurationToHumanReadableFormat(
                scope.row.total_run_time
              )
            }}
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>

    <el-dialog
      v-if="showDetailedLog"
      :visible.sync="showDetailedLog"
      custom-class="editContentItemModal"
      destroy-on-close
      fullscreen
    >
      <detailed-log :task="selectedTask"></detailed-log>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AdvancedToolbar from "@/components/AdvancedToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import DetailedLog from "@/views/analyse/virtual-agent-usage/workflow/pages/DetailedLog";
import { convertDurationInMicroSecondsToHumanReadableForm } from "@/utils/time";
import _ from "lodash";
import BackButtonHandler from "@/components/BackButtonHandler";

export default {
  name: "VirtualAgentUsageForWorkflowList",
  mixins: [BaseContent, BackButtonHandler],
  components: {
    AdvancedToolbar,
    DetailedLog
  },
  data() {
    return {
      showDetailedLog: false,
      selectedTask: null
    };
  },
  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapState("usages", {
      usagesList: state => state.workflowUsages,
      isLoading: state => state.loading
    }),
    ...mapState("appfeatures", {
      featureUsageAndLimit: state => state.featureUsageAndLimit,
      isLoading: state => state.isLoading
    }),
    convertMicroSecondsDurationToHumanReadableFormat() {
      return microSeconds =>
        convertDurationInMicroSecondsToHumanReadableForm(microSeconds);
    },
    checkFeatureWarningLimitReached() {
      if (this.featureUsageAndLimit.featureUsagesLeft === 0) {
        return true;
      }
      if (
        this.featureUsageAndLimit.featureUsagesLeft /
          this.featureUsageAndLimit.featureHardLimit <
        0.05
      ) {
        return true;
      } else {
        return false;
      }
    },

    /**
     * Content for the check warning limit reached tooltip
     * @returns {String}
     */
    checkFeatureWarningLimitTooltip() {
      // eslint-disable-next-line
      return __("Task will not be run via api/scheduler when it will reach to 0");
    }
  },
  mounted() {
    if (this.selectedAccountId !== "all") {
      this.getFeatureUsageAndLimit("workflow");
    }
  },

  methods: {
    ...mapActions("usages", {
      contentAPI: "getWorkflowUsage",
      setTaskForDetailedLogs: "setTaskForDetailedLogs",
      setDateSearchRange: "setDateSearchRange"
    }),
    ...mapActions("appfeatures", {
      getFeatureUsageAndLimit: "getFeatureUsageAndLimit"
    }),

    backButtonHandler() {
      if (this.showDetailedLog) {
        this.showDetailedLog = false;
        return false;
      }
      return true;
    },

    handleDateRangeChanged(val) {
      this.setDateSearchRange(val);
    },

    handleDetailedLogClick(task) {
      this.selectedTask = _.cloneDeep(task);
      this.setTaskForDetailedLogs(this.selectedTask);
      this.showDetailedLog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";

.form-container {
  padding: 20px 0;
}

code {
  margin-left: 5px;
  color: $content-theme-color;
}
</style>
